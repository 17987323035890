let data = [
  //////**** Resource Library ****//////
  {
    path: "/dashboard",
    component: () => import("@/pages/Praveen/Main.vue"),
    meta: {
      layout: "home",
    },
  },
  {
    path: "/buyer/resourcelibrary",
    component: () => import("@/pages/Praveen/ResourceLibrary/Main.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },{
    name: "resourcelibrary-newresource",
    path: "/buyer/resourcelibrary/newresource",
    component: () => import("@/pages/Praveen/ResourceLibrary/Newresource.vue"),
    props: true, 
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },
  {
    path: "/buyer/resourcelibrary/:id",
    component: () => import("@/pages/Praveen/ResourceLibrary/ResourceView.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },
  {
    path: "/buyer/resourcelibrary/:id/edit",
    component: () => import("@/pages/Praveen/ResourceLibrary/Newresource.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },

  //vendor
  {
    path: "/buyer/vendor",
    component: () => import("@/pages/Praveen/Buyer/Vendor/list.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },
  {
    path: "/buyer/vendor/:id",
    component: () => import("@/pages/Praveen/Buyer/Vendor/view.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },

  //Tender
  {
    path: "/buyer/tender",
    component: () => import("@/pages/Praveen/Buyer/Tender/list.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },
  {
    path: "/buyer/tender/add",
    name: "buyer-tender-add",
    component: () => import("@/pages/Praveen/Buyer/Tender/manage.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },

  {
    path: "/buyer/tender/:id/bids",
    name: "buyer-tender-bids",
    component: () => import("@/pages/Praveen/Buyer/Tender/bids.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },
  {
    path: "/buyer/tender/:id/comparebids",
    name: "buyer-tender-comparebids",
    component: () => import("@/pages/Praveen/Buyer/Tender/comparebids.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },

  {
    path: "/buyer/tender/:id/questions",
    name: "buyer-tender-qanswer",
    component: () =>
      import("@/pages/Praveen/Buyer/Tender/Questionandanswer/qanswer.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },
  {
    path: "/buyer/tender/:id/questions/:subid",
    name: "buyer-tender-qanswer-view",
    component: () =>
      import("@/pages/Praveen/Buyer/Tender/Questionandanswer/qanswerview.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },
  {
    path: "/buyer/tender/:id/invite",
    name: "buyer-tender-invite",
    component: () =>
      import("@/pages/Praveen/Buyer/Tender/Vendors/invitevendor.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },
  {
    path: "/buyer/tender/:id",
    name: "buyer-tender-view",
    component: () => import("@/pages/Praveen/Buyer/Tender/view.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },
  {
    path: "/buyer/tender/edit/:id",
    name: "buyer-tender-edit",
    component: () => import("@/pages/Praveen/Buyer/Tender/manage.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },

  {
    path: "/buyer/tender/worklist/:id",
    name: "buyer-tender-worklist",
    component: () => import("@/pages/Praveen/Buyer/Tender/WorkList/main.vue"),
    meta: {
      layout: "empty",
      fortype: "buyer",
    },
  },
  {
    path: "/buyer/tender/c1",
    name: "buyer-tender-compare-c1",
    component: () =>
      import("@/pages/Praveen/Buyer/Tender/CompareBids/comparebids-c1.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },

  {
    path: "/buyer/tender/c2",
    name: "buyer-tender-compare-c2",
    component: () =>
      import("@/pages/Praveen/Buyer/Tender/CompareBids/comparebids-c2.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },

  {
    path: "/buyer/tender/c4",
    name: "buyer-tender-compare-c4",
    component: () =>
      import("@/pages/Praveen/Buyer/Tender/CompareBids/comparebids-c4.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },

  {
    path: "/buyer/tender/c3",
    name: "buyer-tender-compare-c3",
    component: () =>
      import("@/pages/Praveen/Buyer/Tender/CompareBids/comparebids-c3.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },
];

export default data;
