<template>
  <div id="app">
    <notifications />
    <component
      :is="(this.$route.meta.layout || 'header') + '-layout'"
      :user="currentUser"
    >
      <router-view />
    </component>
  </div>
</template>
<style>
/* @font-face {
  font-family: "SFPRO";
  src: local("SFPRO"),   url(./fonts/Merienda/Merienda-Regular.ttf) format("truetype");} */
</style>
<script>
import Main from "./layouts/Main";
import Empty from "./layouts/Empty";
import Home from "./layouts/Home";
import Auth from "./layouts/Auth";
export default {
  components: {
    "main-layout": Main,
    "home-layout": Home,
    "empty-layout": Empty,
    "auth-layout": Auth,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_ADMIN");
      }
      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_MODERATOR");
      }
      return false;
    },
  },
  methods: {
  },
};
</script>
